import React,{useEffect,useRef,useState} from 'react'
import WebTemplate from '../Components/WebTemplate'
import { Link,useNavigate,useSearchParams  } from 'react-router-dom';

import SuccessVideoSection from '../Components/SuccessVideosSection';
//import homeBackgroundVideo from '../assets/videos/course_one.mp4'; // Import the video file

function About() {
  const navigate=useNavigate()

  const learn = [
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink
      title: 'Braclets And Chakras',
      
    },
    {
      gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown
      title: 'Carivng',
    
    },
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink
      title: 'Precious Stones',
     
    },
    {
      gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown
      fontSize:"5px",
      title: 'Semi Precious Stones',
     
    },
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink
        title: 'Silver',
       
      }
  ];
    const aboutScreen=()=>{  
    
        return(
            <>
          
              <main id="main">
              <section id="hero" class="d-flex align-items-center">

<div class="container">
  <div class="row" style={{textAlign:'center',marginTop:"80px"}}>
  
    {/* <div class="col-lg-12 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
    <h1 style={{fontSize:"30px"}}>
       
               About <span style={{color:'rgb(172, 0, 172)', fontFamily: 'Algerian, cursive'}}>Pallavi's </span> 
               <span style={{color:'rgb(251, 40, 164)'}}>Delicious Treats</span>
               
       
        </h1>
    </div> */}
   
  </div>
  
</div>

</section>


              <section id="clients" class="clients">
      <div class="container">

        <div class="row" data-aos="zoom-in">
    
<div className='col-md-12'>
<div class="section-title">
      <h2 style={{color:'#fff',marginBottom:'5px',fontSize:'25px'}}>Who we are</h2>
      
    </div>
    <p style={{fontSize:"23px",textAlign:"justify",color:'white'}}>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
</p>
<div class="section-title" style={{color:'#fff'}}>Our Vision
</div>
<p>
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.

</p>
<p>
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
</p>

    </p>

</div>
        </div>
        <div className='row'>
        <div class="section-title">
      <h2 style={{color:'#fff',marginBottom:'1px',fontSize:'25px'}}>
       What We Serve</h2>
     
    </div>
        <div className="card-container">
     
     { learn.map((color, index) => (
        <div className="card" key={index} style={{ background: color.gradient }}>
          <h2 className="card-title">{color.title}</h2>
        
        </div>
     )
     )
    }
     
    </div>
        <div class="container" style={{marginTop:"20px"}}>
        <div class="section-title">
      <h2 style={{color:'#fff',marginBottom:'1px',fontSize:'25px'}}>
       Frequently Asked Questions</h2>
     
    </div>
      
        <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="true"
              aria-controls="collapseThree"
              style={{fontSize:"20px"}}
            >
             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget justo nec massa condimentum eleifend?
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body" style={{textAlign:'left',fontSize:"18px"}}>
              <strong>
              Quisque euismod nisi ut velit eleifend, at aliquet purus aliquam. Nullam vel velit eu ligula consequat feugiat.</strong><p/>
              
            </div>
          </div>
          
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header"  id="headingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
              style={{fontSize:"20px"}}
            >
           Fusce euismod justo sed mauris molestie, sed condimentum est ullamcorper. ?
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse hide"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body" style={{textAlign:'left',fontSize:"18px"}}>
            
              
            </div>
          </div>
        </div>
      
      
       

      </div>
    
    
  </div>
 
 
     
        </div>

      </div>
      {/* <div class="fluid-container" style={{marginTop:"20px"}}>
     
        <SuccessVideoSection 
        data={[]}
         for_course={"overall"}
         caption={"Testimonials"}
         />
      </div> */}
    

    </section>
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

       
        <div class="row">
         
        </div>

      </div>
    </section>

</main>
            </>
        )
    }
  return (
  <WebTemplate>
    {aboutScreen()}
  </WebTemplate>
  )
}

export default About