import React,{useEffect,useRef,useState} from 'react'
import WebTemplate from '../Components/WebTemplate'

import thumb03 from '../assets/img/marva/thumb03.jpg'
import thumb02 from '../assets/img/marva/thumb02.jpg'
import thumb01 from '../assets/img/marva/thumb01.jpg'

import thamb04 from '../assets/img/marva/thamb04.jpg'
import thamb05 from '../assets/img/marva/thamb05.jpg'

import 'glightbox/dist/css/glightbox.min.css';

import VideoPopup from '../Components/VideoPopup';

import { useGetAllNotesQuery } from '../Services/modules/notes';

import { useNavigate } from 'react-router-dom';
//import homeBackgroundVideo from '../assets/videos/homebg.mp4'; // Import the video file
//import { useGetAllTestimonialsQuery } from '../Services/modules/testimonials';
import { useDispatch } from 'react-redux';

import SuccessVideoSection from '../Components/SuccessVideosSection.js';



function Home() {
  const dispatch=useDispatch()
  const navigate = useNavigate()

const goToClassDetails=(param)=>{ 
  navigate({
    pathname: "/category",
    search: `?categoryname=${param}`,
  });
 }

    const HomeScreen=()=>{        
        return(
            <>
                        
<section id="hero" class="d-flex align-items-center">

<div class="container">
  <div class="row">
    <div class="col-lg-12 d-flex flex-column justify-content-center  pt-lg-0" data-aos="fade-up" data-aos-delay="200">
      {/* <h1 class="text-center" style={{color:"#f82884"}}>Welcome to <span style={{color:"#ac00ac", fontFamily: 'Algerian, cursive'}}>Pallavi's</span> Delicious Treats</h1>
      <h2 class="text-center" style={{color:'white'}}>Create, Enjoy, Explore</h2> */}
    
        {/* <VideoPopup videoId="X2JYmZCZcW4" /> */}
  
    </div>
  </div>
</div>

</section>
 
              <main id="main">

<section id="about" class="about">
  <div class="container" data-aos="fade-up">
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
  <h2 style={{ color: 'black', marginBottom: "0px",fontSize:'25px' }}>
      <span style={{ color: "#ac00ac", fontFamily: 'Algerian, cursive' }}>
      <span style={{ color: "#ac00ac", fontFamily: 'Algerian, cursive'
    
    }}
      > 
    
       </span>
    
      </span>
    </h2>
            

    </div>
     {/* <div class="section-title">
     <video width="50%" height="50%" controls>
        <source src={teatimecakeclass} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
     </div> */}
  
 
 
    <div class="section-title" style={{marginTop:"10px"}}>
      <h2 style={{color:'black',padding:"0px",marginBottom:"0px"}}><span style={{color:"#fff", fontFamily: 'Algerian, cursive'}}>Marva Artisans</span> <span style={{color:"#fb28a4"}}></span> </h2>
    </div>

    <div class="row content ">
      <div class="col-lg-12">
        <p className='textFontSize' style={{textAlign:"justify",color:'white'}}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget justo nec massa condimentum eleifend. Quisque euismod nisi ut velit eleifend, at aliquet purus aliquam. Nullam vel velit eu ligula consequat feugiat. Fusce euismod justo sed mauris molestie, sed condimentum est ullamcorper. Integer consequat magna nec justo luctus, nec condimentum justo fermentum. Vivamus rutrum odio id ante dictum, nec sodales urna tincidunt. Duis interdum justo vel ante dictum efficitur. Sed vehicula nisi sed elit posuere eleifend. Proin vestibulum justo a justo sodales, ut condimentum ipsum laoreet.


        </p>
        <p className='textFontSize' style={{textAlign:"justify",color:'white'}}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget justo nec massa condimentum eleifend. Quisque euismod nisi ut velit eleifend, at aliquet purus aliquam.
        </p>
      
      </div>
    
   

      
    </div>

  </div>
</section>

<section style={{paddingTop:"1px"}}>

</section>
      <section id="cta" class="cta" >
      <div class="container" data-aos="zoom-in" style={{fontSize:"25px"}}>

        <div class="row">
          <div class="col-lg-9 text-center text-lg-start">
          
            <h3 style={{color:'white'}}>Reach us out to order products..
            </h3>
           
          </div>
          <div class="col-lg-3 cta-btn-container text-center">
            <a class="cta-btn align-middle" style={{cursor:'pointer',color:'white'}} onClick={()=>navigate("/contact")}>Contact Us</a>
          </div>
        </div>
       

      </div>
    </section>






<section id="services" class="services">

      <div class="container" data-aos="fade-up">

        <div class="section-title" style={{marginBottom:"20px"}}>
          <h2 style={{color:'#fff',paddingBottom:"5px"}}>Our Products</h2>
          {/* <p style={{fontSize:"22px",color:'white'}}>
          Welcome on board..If you are someone who are passionate about baking, want to learn from scratch, 
start your own business, you are at the right place at the right time. We @PALLAVI's Delicious Treats 
conduct cake workshops offline as well as online. All workshops are 100 percent eggless and hands on
practicals are taught. To enroll for any of the classes or for any enquiry do reach us at 
pallavisdelicioustreats@gmail.com
          </p> */}
        </div>
        <div class="row">
        <div class="col-xl-4 col-md-6 d-flex align-items-stretch" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">
              <a style={{cursor:"pointer"}} onClick={()=>goToClassDetails("bnc")}>
              <div className="course-card">
              <img src={thumb01} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    <span style={{color:"#fff"}}>Braclets and Chakras</span></h3>
     
      {/* <button className="btn btn-primary enroll-button" onClick={()=>goToCourseDetails(courseInfo)}>Enroll</button> */}
      {/* <button className="btn btn-primary enroll-button" onClick={()=>navigate("/livecourses")}>View More</button> */}
 
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6 d-flex align-items-stretch" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">
              <a style={{cursor:"pointer"}} onClick={()=>goToClassDetails("carving")}>
              <div className="course-card">
              <img src={thumb02} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
   <span style={{color:"#fff"}}>Carving</span></h3>
     
      {/* <button className="btn btn-primary enroll-button" onClick={()=>goToCourseDetails(courseInfo)}>Enroll</button> */}
      {/* <button className="btn btn-primary enroll-button" onClick={()=>goToClassDetails("/dnaengineering")}>View More</button> */}
 
</div>
              
                </a>             
           </div>
<div class="col-xl-4 col-md-6 d-flex align-items-stretch" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">
              <a style={{cursor:"pointer"}} onClick={()=>goToClassDetails("semistones")}>
              <div className="course-card">
              <img src={thumb03} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:"white"}}>
   <span style={{color:"#fff"}}>Precious Stones </span></h3>
     
      {/* <button className="btn btn-primary enroll-button" onClick={()=>goToCourseDetails(courseInfo)}>Enroll</button> */}
      {/* <button className="btn btn-primary enroll-button" onClick={()=>goToClassDetails("/onlinecourses")}>View More</button> */}
 
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6 d-flex align-items-stretch" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">
              <a style={{cursor:"pointer"}} onClick={()=>goToClassDetails("semistones")}>
              <div className="course-card">
              <img src={thamb04} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:"white"}}>
   <span style={{color:"#fff"}}>Semi Precious Stones </span></h3>
     
      {/* <button className="btn btn-primary enroll-button" onClick={()=>goToCourseDetails(courseInfo)}>Enroll</button> */}
      {/* <button className="btn btn-primary enroll-button" onClick={()=>goToClassDetails("/onlinecourses")}>View More</button> */}
 
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6 d-flex align-items-stretch" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">
              <a style={{cursor:"pointer"}} onClick={()=>goToClassDetails("silver")}>
              <div className="course-card">
              <img src={thamb05} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:"white"}}>
   <span style={{color:"#fff"}}>Silver </span>
</h3>
     
      {/* <button className="btn btn-primary enroll-button" onClick={()=>goToCourseDetails(courseInfo)}>Enroll</button> */}
      {/* <button className="btn btn-primary enroll-button" onClick={()=>goToClassDetails("/onlinecourses")}>View More</button> */}
 
</div>
              
                </a>             
           </div>
       
           
        </div>

       
     
      </div>
    
      </section>
      {/* <section id="clients" class="clients">

<SuccessVideoSection 
        data={[]} 
         for_course={"home_page"}
         caption={"Testimonials"}
         justifyContent={"left"}
         />
 
</section> */}
      <section id="clients" class="clients" style={{marginBottom:"50px"}}>
    
     

   
    </section>
  
   
</main>
            </>
        )
    }
  return (
  <WebTemplate>
    {HomeScreen()}
  </WebTemplate>
  )
}

export default Home