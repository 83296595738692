import React,{useEffect,useRef,useState} from 'react'

import GLightbox from 'glightbox';

import p01 from '../../assets/img/marva/products/precious_stones/p01.jpg'
import p02 from '../../assets/img/marva/products/precious_stones/p02.jpg'
import p03 from '../../assets/img/marva/products/precious_stones/p03.jpg'
import p04 from '../../assets/img/marva/products/precious_stones/p04.jpg'
import p05 from '../../assets/img/marva/products/precious_stones/p05.jpg'
import p06 from '../../assets/img/marva/products/precious_stones/p06.jpg'
import p07 from '../../assets/img/marva/products/precious_stones/p07.jpg'
import p08 from '../../assets/img/marva/products/precious_stones/p08.jpg'
import p09 from '../../assets/img/marva/products/precious_stones/p09.jpg'
import p10 from '../../assets/img/marva/products/precious_stones/p10.jpg'
import p11 from '../../assets/img/marva/products/precious_stones/p11.jpg'
import p12 from '../../assets/img/marva/products/precious_stones/p12.jpg'
import p13 from '../../assets/img/marva/products/precious_stones/p13.jpg'
import p14 from '../../assets/img/marva/products/precious_stones/p14.jpg'
import p15 from '../../assets/img/marva/products/precious_stones/p15.jpg'
import p16 from '../../assets/img/marva/products/precious_stones/p16.jpg'
import p17 from '../../assets/img/marva/products/precious_stones/p17.jpg'
import p18 from '../../assets/img/marva/products/precious_stones/p18.jpg'
import p19 from '../../assets/img/marva/products/precious_stones/p19.jpg'
import p20 from '../../assets/img/marva/products/precious_stones/p20.jpg'
import p21 from '../../assets/img/marva/products/precious_stones/p21.jpg'
import p22 from '../../assets/img/marva/products/precious_stones/p22.jpg'
import p23 from '../../assets/img/marva/products/precious_stones/p23.jpg'
import p24 from '../../assets/img/marva/products/precious_stones/p24.jpg'
import p25 from '../../assets/img/marva/products/precious_stones/p25.jpg'
import p26 from '../../assets/img/marva/products/precious_stones/p26.jpg'
import p27 from '../../assets/img/marva/products/precious_stones/p27.jpg'
import p28 from '../../assets/img/marva/products/precious_stones/p28.jpg'
import p29 from '../../assets/img/marva/products/precious_stones/p29.jpg'
import p30 from '../../assets/img/marva/products/precious_stones/p30.jpg'



function Stones() {
    const galleryRef = useRef(null);

    const PhotoGalleryScreen=()=>{  
        useEffect(() => {
            if (galleryRef.current) {
              // Initialize GLightbox
              const lightbox = GLightbox({
                selector: '.glightbox',
                touchNavigation: true, // Enable touch navigation
                loop: true, // Enable looping through images
                closeButton: true, // Show close button
                nextButton: true, // Show next button
                prevButton: true, // Show previous button
              });
            }
          }, []);
        return(
            <>
          
              <main id="main">
              {/* <section id="hero" class="d-flex align-items-center">

<div class="container">
  <div class="row" style={{textAlign:'center',marginTop:"80px"}}>
  
    <div class="col-lg-12 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
    <h1 style={{fontSize:"30px"}}>
       
               Check Sponges, Cookies, Cakes and Chocolates made by us  
               
       
        </h1>
    </div>
   
  </div>
  
</div>

</section> */}


              <section id="clients" class="clients">
      <div class="container">

        <div class="row" data-aos="zoom-in">
    
<div className='col-md-12'>
<div class="section-title">
      <h2 style={{color:'rgb(251, 40, 164)',marginBottom:'5px',fontSize:'25px'}}> Precious Stones</h2>
      
    </div>
    <div ref={galleryRef}>
    <div class="row gallery">
        <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p01}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p01} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p03}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p03} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p04}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p04} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p05}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p05} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p06}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p06} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p07}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p07} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p08}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p08} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p09}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p09} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p10}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p10} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p11}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p11} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p12}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p12} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p13}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p13   } className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p14}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p14} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p15}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p15} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p16}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p16} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p18}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p18} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p19}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p19} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p20}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p20} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p21}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p21} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p22}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p22} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p23}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p23} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p24}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p24} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p25}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p25} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p26}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p26} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p27}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p27} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p28}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p28} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p29}
        className="glightbox"
        data-title="Precious Stones"      
      >
              <div className="course-card">
              <img src={p29} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
        </div>
    </div>
    
   

</div>
        </div>
      

      </div>
   
    </section>
  
</main>
            </>
        )
    }
  return (
  <div>
    {PhotoGalleryScreen()}
  </div>
  )
}

export default Stones