import React,{useEffect,useRef,useState} from 'react'

import GLightbox from 'glightbox';

import p01 from '../../assets/img/marva/products/semi_precious_stones/p01.jpg'
import p02 from '../../assets/img/marva/products/semi_precious_stones/p02.jpg'
import p03 from '../../assets/img/marva/products/semi_precious_stones/p03.jpg'
import p04 from '../../assets/img/marva/products/semi_precious_stones/p04.jpg'
import p05 from '../../assets/img/marva/products/semi_precious_stones/p05.jpg'
import p06 from '../../assets/img/marva/products/semi_precious_stones/p06.jpg'
import p07 from '../../assets/img/marva/products/semi_precious_stones/p07.jpg'
import p08 from '../../assets/img/marva/products/semi_precious_stones/p08.jpg'
import p09 from '../../assets/img/marva/products/semi_precious_stones/p09.jpg'
import p10 from '../../assets/img/marva/products/semi_precious_stones/p10.jpg'
import p11 from '../../assets/img/marva/products/semi_precious_stones/p11.jpg'
import p12 from '../../assets/img/marva/products/semi_precious_stones/p12.jpg'
import p13 from '../../assets/img/marva/products/semi_precious_stones/p13.jpg'
import p14 from '../../assets/img/marva/products/semi_precious_stones/p14.jpg'
import p15 from '../../assets/img/marva/products/semi_precious_stones/p15.jpg'
import p16 from '../../assets/img/marva/products/semi_precious_stones/p16.jpg'
import p17 from '../../assets/img/marva/products/semi_precious_stones/p17.jpg'
import p18 from '../../assets/img/marva/products/semi_precious_stones/p18.jpg'
import p19 from '../../assets/img/marva/products/semi_precious_stones/p19.jpg'
import p20 from '../../assets/img/marva/products/semi_precious_stones/p20.jpg'
import p21 from '../../assets/img/marva/products/semi_precious_stones/p21.jpg'
import p22 from '../../assets/img/marva/products/semi_precious_stones/p22.jpg'
import p23 from '../../assets/img/marva/products/semi_precious_stones/p23.jpg'
import p24 from '../../assets/img/marva/products/semi_precious_stones/p24.jpg'
import p25 from '../../assets/img/marva/products/semi_precious_stones/p25.jpg'
import p26 from '../../assets/img/marva/products/semi_precious_stones/p26.jpg'
import p27 from '../../assets/img/marva/products/semi_precious_stones/p27.jpg'
import p28 from '../../assets/img/marva/products/semi_precious_stones/p28.jpg'
import p29 from '../../assets/img/marva/products/semi_precious_stones/p29.jpg'
import p30 from '../../assets/img/marva/products/semi_precious_stones/p30.jpg'
import p31 from '../../assets/img/marva/products/semi_precious_stones/p31.jpg'
import p32 from '../../assets/img/marva/products/semi_precious_stones/p32.jpg'
import p33 from '../../assets/img/marva/products/semi_precious_stones/p33.jpg'
import p34 from '../../assets/img/marva/products/semi_precious_stones/p34.jpg'
import p35 from '../../assets/img/marva/products/semi_precious_stones/p35.jpg'
import p36 from '../../assets/img/marva/products/semi_precious_stones/p36.jpg'
import p37 from '../../assets/img/marva/products/semi_precious_stones/p37.jpg'
import p38 from '../../assets/img/marva/products/semi_precious_stones/p38.jpg'
import p39 from '../../assets/img/marva/products/semi_precious_stones/p39.jpg'
import p40 from '../../assets/img/marva/products/semi_precious_stones/p40.jpg'
import p41 from '../../assets/img/marva/products/semi_precious_stones/p41.jpg'
import p42 from '../../assets/img/marva/products/semi_precious_stones/p42.jpg'
import p43 from '../../assets/img/marva/products/semi_precious_stones/p43.jpg'
import p44 from '../../assets/img/marva/products/semi_precious_stones/p44.jpg'
import p45 from '../../assets/img/marva/products/semi_precious_stones/p45.jpg'
import p46 from '../../assets/img/marva/products/semi_precious_stones/p46.jpg'
import p47 from '../../assets/img/marva/products/semi_precious_stones/p47.jpg'
import p48 from '../../assets/img/marva/products/semi_precious_stones/p48.jpg'
import p49 from '../../assets/img/marva/products/semi_precious_stones/p49.jpg'
import p50 from '../../assets/img/marva/products/semi_precious_stones/p50.jpg'
import p51 from '../../assets/img/marva/products/semi_precious_stones/p51.jpg'
import p52 from '../../assets/img/marva/products/semi_precious_stones/p52.jpg'
import p53 from '../../assets/img/marva/products/semi_precious_stones/p53.jpg'
import p54 from '../../assets/img/marva/products/semi_precious_stones/p54.jpg'
import p55 from '../../assets/img/marva/products/semi_precious_stones/p55.jpg'
import p56 from '../../assets/img/marva/products/semi_precious_stones/p56.jpg'
import p57 from '../../assets/img/marva/products/semi_precious_stones/p57.jpg'
import p58 from '../../assets/img/marva/products/semi_precious_stones/p58.jpg'
import p59 from '../../assets/img/marva/products/semi_precious_stones/p59.jpg'
import p60 from '../../assets/img/marva/products/semi_precious_stones/p60.jpg'
import p61 from '../../assets/img/marva/products/semi_precious_stones/p61.jpg'
import p62 from '../../assets/img/marva/products/semi_precious_stones/p62.jpg'
import p63 from '../../assets/img/marva/products/semi_precious_stones/p63.jpg'
import p64 from '../../assets/img/marva/products/semi_precious_stones/p64.jpg'
import p65 from '../../assets/img/marva/products/semi_precious_stones/p65.jpg'
import p66 from '../../assets/img/marva/products/semi_precious_stones/p66.jpg'
import p67 from '../../assets/img/marva/products/semi_precious_stones/p67.jpg'
import p68 from '../../assets/img/marva/products/semi_precious_stones/p68.jpg'
import p69 from '../../assets/img/marva/products/semi_precious_stones/p69.jpg'
import p70 from '../../assets/img/marva/products/semi_precious_stones/p70.jpg'
import p71 from '../../assets/img/marva/products/semi_precious_stones/p71.jpg'
import p72 from '../../assets/img/marva/products/semi_precious_stones/p72.jpg'
import p73 from '../../assets/img/marva/products/semi_precious_stones/p73.jpg'
import p74 from '../../assets/img/marva/products/semi_precious_stones/p74.jpg'
import p75 from '../../assets/img/marva/products/semi_precious_stones/p75.jpg'
import p76 from '../../assets/img/marva/products/semi_precious_stones/p76.jpg'
import p77 from '../../assets/img/marva/products/semi_precious_stones/p77.jpg'
import p78 from '../../assets/img/marva/products/semi_precious_stones/p78.jpg'



function PreciousStones() {
    const galleryRef = useRef(null);

    const PhotoGalleryScreen=()=>{  
        useEffect(() => {
            if (galleryRef.current) {
              // Initialize GLightbox
              const lightbox = GLightbox({
                selector: '.glightbox',
                touchNavigation: true, // Enable touch navigation
                loop: true, // Enable looping through images
                closeButton: true, // Show close button
                nextButton: true, // Show next button
                prevButton: true, // Show previous button
              });
            }
          }, []);
        return(
            <>
          
              <main id="main">
              {/* <section id="hero" class="d-flex align-items-center">

<div class="container">
  <div class="row" style={{textAlign:'center',marginTop:"80px"}}>
  
    <div class="col-lg-12 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
    <h1 style={{fontSize:"30px"}}>
       
             
               
       
        </h1>
    </div>
   
  </div>
  
</div>

</section> */}


              <section id="clients" class="clients">
      <div class="container">

        <div class="row" data-aos="zoom-in">
    
<div className='col-md-12'>
<div class="section-title">
      <h2 style={{color:'rgb(251, 40, 164)',marginBottom:'5px',fontSize:'25px'}}>Semi Precious Stones</h2>
      
    </div>
    <div ref={galleryRef}>
    <div class="row gallery">
        <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p01}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p01} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p03}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p03} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p04}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p04} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p05}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p05} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p06}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p06} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p07}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p07} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p08}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p08} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p09}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p09} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p10}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p10} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p11}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p11} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p12}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p12} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p13}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p13   } className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p14}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p14} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p15}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p15} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p16}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p16} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p18}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p18} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p19}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p19} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p20}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p20} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p21}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p21} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p22}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p22} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p23}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p23} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p24}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p24} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p25}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p25} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p26}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p26} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p27}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p27} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p28}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p28} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p29}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p29} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p30}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p30} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p31}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p31} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p32}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p32} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p33}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p33} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p34}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p34} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p35}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p35} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p36}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p36} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p37}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p37} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p38}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p38} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p39}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p39} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p40}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p40} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p41}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p41} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p42}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p42} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p43}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p43} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p44}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p44} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p45}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p45} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p46}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p46} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p47}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p47} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p48}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p48} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p49}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p49} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p50}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p50} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p51}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p51} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p52}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p52} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p53}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p53} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p54}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p54} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p55}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p55} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p56}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p56} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p57}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p57} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p58}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p58} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p59}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p59} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p60}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p60} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p61}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p61} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p62}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p62} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p63}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p63} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p64}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p64} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p65}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p65} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p66}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p66} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p67}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p67} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p68}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p68} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p69}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p69} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p70}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p70} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p71}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p71} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p72}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p72} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p73}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p73} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p74}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p74} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p75}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p75} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p76}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p76} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p77}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p77} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p78}
        className="glightbox"
        data-title="Semi Precious Stones"      
      >
              <div className="course-card">
              <img src={p78} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           


        </div>
    </div>
    
   

</div>
        </div>
      

      </div>
   
    </section>
  
</main>
            </>
        )
    }
  return (
  <div>
    {PhotoGalleryScreen()}
  </div>
  )
}

export default PreciousStones