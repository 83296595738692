import React,{useEffect,useRef,useState} from 'react'
import WebTemplate from '../Components/WebTemplate'
import { Link,useNavigate,useSearchParams  } from 'react-router-dom';

import { useGetAllTestimonialsQuery } from '../Services/modules/testimonials';
import SuccessVideoSection from '../Components/SuccessVideosSection';
import Photos from './Photos';
import Bnc from '../Components/Images/Bnc';
import Carving from '../Components/Images/Carving';
import PreciousStones from '../Components/Images/PreciousStones';
import Silver from '../Components/Images/Silver';
import Stones from '../Components/Images/Stones';
//import homeBackgroundVideo from '../assets/videos/course_one.mp4'; // Import the video file

function Category() {
  const navigate=useNavigate()

    const [searchParams] = useSearchParams();
    let category_name=searchParams.get('categoryname')
    const classname = category_name=="bnc" ? 'heroProduct d-flex align-items-center' : 
    category_name=="carving" ?
    'heroProductCarving d-flex align-items-center': 
    category_name=="stones" ?
    'heroProductPreciousStone d-flex align-items-center':
    category_name=="semistones" ?
    'heroProductSPStone d-flex align-items-center':
    category_name=="silver" ?
    'heroProductSilver d-flex align-items-center':
    ""

  const{data:testimonialsData=[],isSuccess:isTestimonialsSuccess,isLoading:isTestimonialsLoading,error:isTestimonialsError,refetch}=useGetAllTestimonialsQuery()
  const[allTestimonials,setAllTestimonials]=useState([])
 
  useEffect(()=>{
    //refetch()
    console.log("className",category_name)
    if(category_name=="" || category_name==null)
    {
        console.log("In if")
        navigate({
            pathname: "/",
           
          });
    }
  },[])
 
  const spongeclass = [
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink
      title: 'White sponge',
      
    },
    {
      gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown
      title: 'Chocolate sponge',
    
    },
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',
      title: 'Red velvet sponge',
     
    },
    {
      gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown
      fontSize:"5px",
      title: 'Pistachio sponge',
     
    },
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink
        title: 'Sponge from premix',
       
      }
  ];
  const cookieClass = [
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink
      title: 'Butter cookies',
     
    },
    {
      gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown
      title: 'Ginger cookies',
    
    },
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink
      title: 'Til cookies',
     
    },
    {
      gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown
      fontSize:"5px",
      title: 'Chocolate cookies',
     
    },
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink
        title: 'Red velvet cookies',
       
      },
      {
        gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown

        title: 'Pista cookies',
       
      },
      {
        gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink

        title: 'Oats cookies',
       
      },
      {
        gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown

        title: 'Shrewsberry cookies',       
      },
      {
        gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink

        title: 'Nankatai biscuit',       
      },
      {
        gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown
        title: 'Melting moments',       
      }
  ];
  const softCakesClass = [
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink
      title: 'Parsi Mawa cake',
     
    },
    {
      gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown
      title: 'Buttery soft cake',
    
    },
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink
      title: 'Hot milk cake',
     
    },
    {
      gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown
      fontSize:"5px",
      title: 'Pound cake',
     
    },
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink
        title: 'Classic carrot cake',
       
      },
      {
        gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown
        title: 'Apple walnut cake',
       
      },
      {
        gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink
        title: 'Lemon poppy seed cake',
       
      },
      {
        gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown
        title: 'Rawa cake',       
      }
     
  ];
  const cupCakeClass = [
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink
      title: 'Vanilla cupcakes with whipped cream frosting ',
     
    },
    {
      gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown
      title: 'Chocolate cupcakes with Dutch cream frosting ',
    
    },
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink
      title: 'Red velvet cupcakes with  cheese cream frosting',
     
    }
  ];
  const beginnerCakeClass = [
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink
      title: 'Learn Basic Sponge from scratch',
     
    },
    {
      gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown
      title: 'Chocolate cake with ganache making',
    
    },
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink
      title: 'Pineapple Simple design cake',
     
    }
  ];
  const basicDesignerClass = [
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink
      title: 'Learn Sponge from scratch',
     
    },
    {
      gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown
      title: 'Chocolate cake with ganache making',
    
    },
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink
      fontSize:"5px",    
      title: 'Red velvet cake with cheese cream',
     
    },
    {
      gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown
   //   fontSize:"5px",
      title: 'Glass / Marble effect',
     
    },
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink
        title: 'Glaze effect',
       
      },
      {
        gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown

        title: 'Fault line',
       
      },
      {
        gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink


        title: 'Doll cake',
       
      },
      {
        gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown

        title: 'Shimmer effect',       
      }
     
  ];
  const advanceDesignerClass = [
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink

      title: 'Learn Sponge from scratch',
     
    },
    {
      gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown
      title: 'Golden / Silver effect',
    
    },
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink

      title: 'Gel carving',
     
    },
    {
      gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown

      fontSize:"5px",
      title: 'Artificial geode',
     
    },
    {
      gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink

        title: 'Origami',
       
      },
      {
        gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown


        title: 'Criss cross cake',
       
      },
      {
        gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink


        title: 'Texture effect',
       
      },
      {
        gradient: 'linear-gradient(to right, #FFD700, #800080)',//brown


        title: 'Frame cake',       
      },
      {
        gradient: 'linear-gradient(to right, #FF66B2, #8A2BE2)',//pink


        title: 'Painting cake',
       
      },
     
  ];
  
 
    const categoryScreen=()=>{  
    
        return(
            <>
         
              <main id="main">
              <section class={classname} style={{height:'60vh'}}>

<div class="container">
  <div class="row" style={{textAlign:'center',marginTop:"80px"}}>
  
    <div class="col-lg-12 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
    <h1 style={{fontSize:"30px"}}>
        {
            category_name=="bnc"?(
                <>
               <span style={{color:'#fff'}} className='text-overlay'>Bracelate and chakras</span> 
                </>
            ):
            category_name=="carving"?(
                <>
              <span style={{color:'#fff'}} className='text-overlay'>Carving</span> 
                </>
            ):
            category_name=="stones"?(
                <>
               <span style={{color:'#fff'}} className='text-overlay'>Precious Stones</span> 
                </>
            ): category_name=="semistones"?(
                <>
                 <span style={{color:'#fff'}} className='text-overlay'>Semi Precious Stones</span> 
                </>
            ): category_name=="silver"?(
                <>
                 <span style={{color:'#fff'}} className='text-overlay'>Silver</span> 
                </>
            ):
           
           ""
        }
       
        </h1>
    </div>
   
  </div>
  
</div>

</section>


              <section id="clients" class="clients">
      <div class="container">

        <div class="row" data-aos="zoom-in">
    
<div className='col-md-12'>
<div class="section-title">
      <h2 style={{color:'white',marginBottom:'5px',fontSize:'25px'}}>
        About The 
      {category_name==="bnc"? " Braclets And Chakras":
      category_name==="carving"? " Carving":
      category_name==="stones"?" PreciousStones":
      category_name==="semistones"?" Semi Precious Stones":
      category_name==="silver"?" Silver":
      "" 
      }
      </h2>
  
    </div>
    {
        category_name==="bnc"?
         <p style={{fontSize:"23px",textAlign:"justify",color:'white'}}>
     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget justo nec massa condimentum eleifend. Quisque euismod nisi ut velit eleifend, at aliquet purus aliquam. Nullam vel velit eu ligula consequat feugiat. Fusce euismod justo sed mauris molestie, sed condimentum est ullamcorper. Integer consequat magna nec justo luctus, nec condimentum justo fermentum. 
    </p>:
     category_name==="carving"?
     <p style={{fontSize:"23px",textAlign:"justify",color:'white'}}>
     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget justo nec massa condimentum eleifend. Quisque euismod nisi ut velit eleifend, at aliquet purus aliquam. Nullam vel velit eu ligula consequat feugiat. Fusce euismod justo sed mauris molestie, sed condimentum est ullamcorper. Integer consequat magna nec justo luctus, nec condimentum justo fermentum. 
    </p>:
      category_name==="stones"?
      <p style={{fontSize:"23px",textAlign:"justify",color:'white'}}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget justo nec massa condimentum eleifend. Quisque euismod nisi ut velit eleifend, at aliquet purus aliquam. Nullam vel velit eu ligula consequat feugiat. Fusce euismod justo sed mauris molestie, sed condimentum est ullamcorper. Integer consequat magna nec justo luctus, nec condimentum justo fermentum. 
     </p>:
      category_name==="semistones"?
      <p style={{fontSize:"23px",textAlign:"justify",color:'white'}}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget justo nec massa condimentum eleifend. Quisque euismod nisi ut velit eleifend, at aliquet purus aliquam. Nullam vel velit eu ligula consequat feugiat. Fusce euismod justo sed mauris molestie, sed condimentum est ullamcorper. Integer consequat magna nec justo luctus, nec condimentum justo fermentum. 
     </p>:
      category_name==="silver"?
      <p style={{fontSize:"23px",textAlign:"justify",color:'white'}}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget justo nec massa condimentum eleifend. Quisque euismod nisi ut velit eleifend, at aliquet purus aliquam. Nullam vel velit eu ligula consequat feugiat. Fusce euismod justo sed mauris molestie, sed condimentum est ullamcorper. Integer consequat magna nec justo luctus, nec condimentum justo fermentum. 
     </p>:
     
   ""

    }

</div>
        </div>
   
      </div>
     

    </section>
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

   {    
      category_name=="bnc"?(
                <>
          <Bnc/>
                </>
            ):
            category_name=="carving"?(
            <Carving/>
            ):
            category_name=="stones"?(
              <Stones/>
              ):
              category_name=="semistones"?(
                <PreciousStones/>
                ):
                category_name=="silver"?(
                  <Silver/>
                  ):
            ""}
           

      </div>
    </section>

</main>
            </>
        )
    }
  return (
  <WebTemplate>
    {categoryScreen()}
  </WebTemplate>
  )
}

export default Category