export const Config = {
   
    // API_URL_LOCAL: 'http://localhost:8001/api/',
    // API_URL_FILE: 'http://localhost:8001',
    
   // API_CLOUDFRONT:'https://d2p2mde5k2x6t0.cloudfront.net' //from my account
   API_CLOUDFRONT:'https://d30vp2pixk7dq1.cloudfront.net',
    // API_URL_LOCAL: 'http://backend.shalamanage.com/api/',
    // API_URL_FILE: 'http://backend.shalamanage.com',
    //rzp_test_key:"rzp_test_qHRn0LpjdY3K50",
    API_URL_LOCAL: 'https://backend.drsandeeppatil.com/api/',
    API_URL_FILE: 'https://backend.drsandeeppatil.com',
    rzp_live_key:"rzp_live_OwpbCKbGl05jkV"
    
 
  }
  