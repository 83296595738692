import React,{useEffect,useRef,useState} from 'react'

import GLightbox from 'glightbox';

import p01 from '../../assets/img/marva/products/carving/p01.jpg'
import p02 from '../../assets/img/marva/products/carving/p02.jpg'
import p03 from '../../assets/img/marva/products/carving/p03.jpg'
import p04 from '../../assets/img/marva/products/carving/p04.jpg'
import p05 from '../../assets/img/marva/products/carving/p05.jpg'
import p06 from '../../assets/img/marva/products/carving/p06.jpg'


function Carving() {
    const galleryRef = useRef(null);

    const PhotoGalleryScreen=()=>{  
        useEffect(() => {
            if (galleryRef.current) {
              // Initialize GLightbox
              const lightbox = GLightbox({
                selector: '.glightbox',
                touchNavigation: true, // Enable touch navigation
                loop: true, // Enable looping through images
                closeButton: true, // Show close button
                nextButton: true, // Show next button
                prevButton: true, // Show previous button
              });
            }
          }, []);
        return(
            <>
          
              <main id="main">
              {/* <section id="hero" class="d-flex align-items-center">

<div class="container">
  <div class="row" style={{textAlign:'center',marginTop:"80px"}}>
  
    <div class="col-lg-12 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
    <h1 style={{fontSize:"30px"}}>
       
               Check Sponges, Cookies, Cakes and Chocolates made by us  
               
       
        </h1>
    </div>
   
  </div>
  
</div>

</section> */}


              <section id="clients" class="clients">
      <div class="container">

        <div class="row" data-aos="zoom-in">
    
<div className='col-md-12'>
<div class="section-title">
      <h2 style={{color:'rgb(251, 40, 164)',marginBottom:'5px',fontSize:'25px'}}>Carivng</h2>
      
    </div>
    <div ref={galleryRef}>
    <div class="row gallery">
        <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p01}
        className="glightbox"
        data-title="Carving"      
      >
              <div className="course-card">
              <img src={p01} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p03}
        className="glightbox"
        data-title="Carving"      
      >
              <div className="course-card">
              <img src={p03} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p04}
        className="glightbox"
        data-title="Carving"      
      >
              <div className="course-card">
              <img src={p04} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p05}
        className="glightbox"
        data-title="Carving"      
      >
              <div className="course-card">
              <img src={p05} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           <div class="col-xl-4 col-md-6" 
style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">          
              <a
        href={p06}
        className="glightbox"
        data-title="Carving"      
      >
              <div className="course-card">
              <img src={p06} className="course-image" alt="Course Thumbnail" />

   <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
    </h3>
     
   
</div>
              
                </a>             
           </div>
           
        </div>
    </div>
    
   

</div>
        </div>
      

      </div>
   
    </section>
  
</main>
            </>
        )
    }
  return (
  <div>
    {PhotoGalleryScreen()}
  </div>
  )
}

export default Carving